
let pwaPrompt
export default {
  name: 'PWADialog',
  data() {
    return {
      code: null,
      dialog: false,
      show: false,
    }
  },
  mounted() {
    // enable PWA button
    let self = this
    this.$nextTick(() => {
      window.addEventListener('beforeinstallprompt', function (e) {
        e.preventDefault()
        // console.log('prompt', self.show)
        pwaPrompt = e

        self.enablePWA()
      })

      // console.log('prompt', this.show)
    })
  },
  computed:{
    allowPWA(){
      return  this.show;
    }
  },
  methods: {
    enablePWA(){
      this.show = true
    },
    // display prompt to enable PWA mode
    goToAppMode() {
      this.show = false
      pwaPrompt.prompt()
      pwaPrompt.userChoice.then(function (choiceResult) {
        pwaPrompt = null
      })



      this.dialog = false

      // rememebr me enabled when user is in PWA mode
      this.$store.dispatch('auth/setRememberMePreference', 1)
    },
  },
}
